.btn-row {
  margin-top: 20px;
  padding-top: 20px;

  .btn + .btn {
    margin-left: 10px;
  }
}

.btn-row-border-top {
  border-top: 1px solid $primary-variant-light;
}
