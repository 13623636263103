.submissions-table-quantity {
  text-align: center;
}

.submissions-table-date {
  // take up only space of content
  white-space: nowrap;
  width: 1%;
}

.submissions-table-payout {
  // take up only space of content
  white-space: nowrap;
  width: 1%;
}
