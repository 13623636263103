// Tooltip/Popover styles

.popover {
  background-color: $scout-primary;
  color: $white;
  font-size: 12px;
  font-style: italic;
  left: 200px;
  border: 0;

  &.top > .arrow,
  &.bottom > .arrow {
    &::after {
      margin-left: -$popover-arrow-width;
      border-width: 0;
      border-color: transparent;
    }
  }

  &.top > .arrow {
    left: $popover-arrow-width - 1;
    margin-left: 0;
    border-width: $popover-arrow-width $popover-arrow-width 0 0;
    border-color: $popover-arrow-outer-color transparent transparent transparent;
    bottom: -$popover-arrow-width + 2;
  }

  &.bottom > .arrow {
    left: $popover-arrow-width - 1;
    margin-left: 0;
    border-width: $popover-arrow-width 0 0 $popover-arrow-width;
    border-color: transparent transparent transparent $popover-arrow-outer-color;
    top: -$popover-arrow-width + 2;
  }

  &.right {
    margin-left: 23px;
  }

  &.right > .arrow {
    border-color: $scout-primary $scout-primary transparent transparent;
    border-width: 10px;
    margin-top: -10px;

    &::after {
      border-color: $white $white transparent transparent;
      border-width: 10px;
      left: -8px;
      bottom: -11px;
    }
  }
}

.popover-light-content {
  // extra padding on the right - 25px - is to account for the
  // close "X" button, so that the text doesn't run into it
  padding: 10px 25px 10px 15px;
}

.popover-trigger {
  @extend .cursor-pointer;

  padding-left: 5px;

  &.is-link {
    @extend .underline;

    margin-left: 10px;
  }

  .stack-detail & {
    @extend .underline;

    color: $scout-action;
  }
}

.roi-rating {
  .popover-trigger {
    padding-left: 0;
    margin-right: 5px;
  }
}

// share history popover; different colors (TODO), text style and max size

.popover-light {
  @extend .popover;

  background-color: $white;
  color: $scout-primary;
  left: 200px;
  border: 1px solid $scout-primary;
  font-style: normal;
  font-size: $font-size-base;
  margin-top: 10px;
  max-width: 500px;

  &.bottom > .arrow {
    left: $popover-arrow-width - 1;
    margin-left: 0;
    border-width: 18px 0 0 18px;
    border-color: transparent transparent transparent $popover-arrow-outer-color;
    top: -18px;

    &::after {
      margin: -17px;
      border-width: 16px 0 0 16px;
      border-color: transparent transparent transparent $white;
    }
  }

  &.right > .arrow {
    margin-top: -10px;
  }
}

.popover-light-details {
  .no-rating {
    font-weight: 600;
  }

  .rating-item {
    margin-bottom: 4px;
    align-items: center;
    display: flex;
  }

  .rating-inline {
    padding-left: 0;
    margin-top: 3px;
    margin-left: 3px;
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 2px 4px;
  }
}

.ratings-block {
  + .ratings-block {
    border-top: 1px solid $gray-lighter;
    margin-top: 21px;
    padding-top: 21px;
  }
}
