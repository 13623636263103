// Copyright 2023-2024 Scout Exchange, LLC. All Rights Reserved.

.text-block {
  color: $next-text-base;

  + .text-block {
    border-left: 1px solid $primary-variant-light;
    padding-left: spacer(2);
    margin-left: spacer(2);
  }
}

.text-block-header {
  color: $white;
  font-size: $font-size-mobile-nav;
  font-weight: $font-weight-bold;
  line-height: 1.29; // ~18px

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }
}

.text-block-secondary {
  color: $primary-variant-dark;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: 1.36;
  margin-top: spacer(1);

  @include media-breakpoint-up(md) {
    font-size: $font-size-sm;
  }

  // need "rating-inline-text" selector here
  // to override color coming from scout-ui
  .rating-inline-text {
    color: $primary-variant-dark;
  }
}

.text-block-warning {
  color: $brand-warning;
  font-weight: $font-weight-bold;
}
