// coding:utf-8
// Copyright 2023 Scout Exchange, LLC. All Rights

// Payments/Dashboard
// --------------------------------------------------

.payments-container {
  @extend %flex-column;

  flex: 1;
  width: 100%;
}

.payments-layout {
  display: flex;
  grid-gap: spacer(4);
  flex-flow: column wrap;
  flex: 1;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-flow: row nowrap;
  }

  nav {
    text-align: center;
  }
}

.payments-column {
  @extend %flex-column;

  flex: 1;
}

// ===== Search bar with filters

.payments-controls {
  border-bottom: 1px solid $primary-variant-light;
  display: flex;
  flex-wrap: wrap;
  grid-gap: spacer(4);
  margin-top: spacer(6);
  margin-bottom: spacer(4);
  padding-bottom: spacer(4);

  // remove divider border when fields become inline
  @include media-breakpoint-up(sm) {
    border-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
}

.payments-controls-search {
  flex: 1 1 50%;

  @include media-breakpoint-up(md) {
    max-width: spacer(120);
  }
}

.payments-controls-type {
  display: flex;
  column-gap: 8px;
  border-left: 1px solid $primary-variant-light;
  padding-left: 16px;
  margin-left: 16px;

  .btn {
    margin-bottom: 0;
    padding: 7px 14px; // 2px shorter than default aligning height with other controls

    @include media-breakpoint-down(sm) {
      font-size: $font-size-mobile-forms;
      padding: 5px 14px; // another 2px shorter to compensate for font size only changing here
      height: auto;
    }
  }
}

.payments-controls-currency {
  border-left: 1px solid $primary-variant-light;
  padding-left: 16px;
  margin-left: 0;
}

// ===== Header boxes that display totals

.payments-total {
  background-color: $primary-variant-lighter;
  border: 1px solid $primary-variant-light;
  display: flex;
  align-items: center;
  padding: spacer(2) spacer(4);
  min-height: spacer(18);
}

.payments-total-title {
  font-size: px-to-rem(22);
  line-height: 1.5;
  margin: 0;
}

.payments-total-date {
  color: $text-muted;
}

.payments-total-amount {
  margin-left: auto;

  .currency-amount {
    text-align: right;
    font-size: px-to-rem(22);
  }
}

// ===== Data tables

// add extra space to left and right of tables
// instead of trying to target nth <td>s
.payment-table-wrap {
  margin-top: spacer(4);
  min-height: 34rem;
  overflow: auto;
  position: relative;
  flex: 1;
  border-bottom: 1px solid $table-border-color;
}

.payment-table-head {
  background-color: $white;
  position: sticky;
  top: 0;

  // "z-index" to position <thead> over edited values
  z-index: 1;

  // "box-shadow" create bottom separator for when
  // table content scrolls "under" the header
  // because bottom-border on <th>s scroll with content
  box-shadow: 0 1px 0 $primary-variant-light;
}

.payment-header-payday {
  position: relative;
  text-align: right;
  white-space: nowrap;

  // create a white mask to hide info icon when it
  // scrolls up past the <thead>
  &::after {
    content: "";
    background-color: $white;
    right: -21px;
    bottom: -2px;
    width: 24px;

    // needed height greater than 100% to cover more space
    height: 120%;
    position: absolute;
    z-index: -1;
  }
}

.payments-data {
  table-layout: fixed;
  width: 92%;
  margin-bottom: auto;
  position: absolute;
  top: 0;
  left: 4%;
  border-bottom: 1px solid $table-border-color;

  // override Bootstrap's value of "top"
  tbody > tr > td {
    vertical-align: middle;
  }
}

.payments-data-is-adjusted {
  .payments-data-amount-original {
    text-decoration: line-through 2px $primary-variant-darkest;
  }
}

.payments-data-adjusted-info {
  position: absolute;
  top: spacer(0.75);

  .popover-trigger {
    padding-left: spacer(1.5);
  }
}

.payments-data-date {
  color: $text-muted;
  white-space: nowrap;
}

.payments-data-candidate,
.payments-data-company {
  @include ellipsis;

  display: block;
}

.payments-none {
  margin-top: 150px;
  text-align: center;
}
