// Copyright 2024 Scout Exchange, LLC. All Rights Reserved.

//  rating_option styles

@use "sass:color";

.dropdown-rating-option-wrapper {
  font-size: 1rem;
  background-color: color.adjust($neutral-black, $whiteness: 9%);
  border-bottom: 1px solid $warm-neutral-2;
  color: $white;
  margin-bottom: px-to-rem(10);
  padding: px-to-rem(18);
  line-height: 1.6;

  @include media-breakpoint-up(md) {
    font-size: $font-size-sm;
    margin: px-to-rem(-6) px-to-rem(-6) px-to-rem(6) px-to-rem(-6);
    padding: px-to-rem(14);
    width: px-to-rem(300);
  }

  .rating-label {
    font-weight: $font-weight-bold;
  }

  .rating-wrapper {
    align-items: center;
    display: flex;
  }

  .rating-info {
    margin: unset;
  }

  .more-info {
    padding: unset;
    border: unset;
    display: inline; // override "block" display for all other <a>
  }
}
