// Scout Variables
// Should be loaded before the main Bootstrap styles

// --------------------------------------------------

@use "sass:math";
@use "sass:color";

// == Colors

// ## Gray and brand colors for use across Bootstrap.

// ** Named Base Colors

// Names are intentionally arbitrary. See: The Crayola Method
// https://coryetzkorn.com/blog/color-variables-in-sass/

// Color variable names generated from:
// http://chir.ag/projects/name-that-color

// NAMING CONVENTION -----

// Multi-word names:
// separate all words with a hyphen:
// ex. $wild-sand

// Duplicate names:
// use sass color functions to differentiate and include the function
// and value in the color name, separated from color with dash:
// ex: $silver-darken9

// ** named colors

$silver-darken9: color.adjust($silver, $lightness: -9%);
$foam: #e6f6fd;
$tuft-bush: #ffe0cc;
$limed-spruce: #333e48;
$scout-gray: $limed-spruce;
$next-text-base: $scout-gray;
$data-viz-6: #b8ccea;
$extended-1: #642f6c;
$brand-special: $extended-1;

// ** scout-status variations
$status-variant-lightest: $tuft-bush;

// ** star rating variants
$star-hiring: $scout-primary;
$star-staffing: $scout-primary;

// color of the "focus" state in Chrome and Firefox
$browser-focus: $cornflower-blue;
$popover-max-width: 500px;

// == Animation and Motion

// ## Shareable settings for animation and motion

// got ease info from http://easings.net/
$ease-type: cubic-bezier(0.23, 1, 0.32, 1);


// == Icons

// ## Settings icons.
$svg-icon-xs-size: 16px;
$svg-icon-xs-padding: 2px;
$svg-icon-sm-size: 24px;
$svg-icon-sm-padding: 5px;
$svg-icon-lg-size: 50px;
$svg-hover-bg: $foam;
$svg-hover-border: $scout-action;
$svg-hover-fill: $white;
$svg-hover-stroke: $scout-action;
$svg-active-bg: $scout-primary;
$svg-active-border: $scout-primary;
$svg-active-fill: $scout-primary;
$svg-active-stroke: $white;
$svg-disabled-bg: $wild-sand;
$svg-disabled-border: $silver-darken9;
$svg-disabled-color: $silver-darken9;
$svg-disabled-fill: $wild-sand;
$svg-disabled-stroke: $silver-darken9;


// == Typography

// ## Font, line-height, and color for body text, headings, and more.

$font-size-h1: math.ceil(($font-size-base * 2.57)); // ~36px
$font-size-h2: math.floor(($font-size-base * 1.93)); // ~27px
$font-size-h3: math.ceil(($font-size-base * 1.55)); // ~22px
$font-size-h4: math.ceil(($font-size-base * 1.25)); // ~18px


// == Forms

// ##

// ** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;


// == Form states and alerts

// ## Define colors for form feedback states and, by default, alerts.

$state-info-text: $brand-info !default;
$state-info-bg: $brand-info !default;
$state-info-border: $brand-info !default;


// == Alerts

// ## Define alert colors, border radius, and padding.

$alert-info-text: $scout-primary !default;
$alert-info-bg: $status-variant-lightest !default;
$alert-info-border: $status-variant-lightest !default;
$alert-message-bg: $scout-primary;
$alert-message-text: $white;
$alert-message-border: $scout-primary;


// == Badges

// ##

$badge-bg: $scout-status;
$badge-font-weight: 600;
$only-in-scout-text: $brand-success;


// == ROI Rating
$roi-rating-poor: $brand-danger;
$roi-rating-medium: $brand-warning;
$roi-rating-good: $brand-success;

// == Fee Split color

// ##
$fee-split: $crusta;


// Tell us more questions color
$highlight: rgb(216 255 0 / 16%);
$tblock-radius: 4px;

// seems counter-intuitive that we use "black" for the "light-hover-bg" color
// and "white" for the "dark-hover-bg" color, but these are correct
$tblock-light-hover-bg: rgba($black, 0.04);
$tblock-dark-hover-bg: rgba($white, 0.04);
