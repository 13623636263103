// Copyright 2024 Scout Exchange, LLC. All Rights Reserved.

// Header styles

// ============================================================ //
// Overrides and necessary tweaks
// ============================================================ //

@use "sass:map";

.navbar {
  // limit container-fluid width to the width of the content
  // to allow for fluidity when resizing, but prevent nav
  // from being wider than the content
  .container-fluid {
    max-width: map.get($container-max-widths, "xl");

    // remove padding to avoid width "jump" on small sizes
    // "navbar" wrapper has left/right padding, so this won't hit the edges
    padding: 0;
  }

  .btn-group-dropdown {
    // displays dropdown menu under the trigger for
    // XS and SM screens. Doesn't matter for MD+
    // because menus are position: absolute;
    flex-direction: column;
    padding: px-to-rem(6) 0;
  }

  .badge-light {
    border-radius: px-to-rem(4);
    background-color: $primary-variant-dark;
    margin-left: px-to-rem(6);
    padding-left: px-to-rem(4);
    padding-right: px-to-rem(4);
  }
}

// ============================================================ //
// Custom styles
// ============================================================ //

// frameless = no header nav
.frameless-header {
  margin-bottom: 45px;
  margin-top: 55px;
  text-align: center;

  .aquent-scout-logo {
    box-sizing: content-box;
    padding-right: 10px;
    padding-left: 10px;
    width: 275px;
  }
}

.navbar-userinfo {
  margin: px-to-rem(4) 0;
  padding: 0 px-to-rem(6);
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 0 px-to-rem(4);
  }

  // vertically align .popover-trigger
  .popover-trigger {
    display: flex;
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }

  .btn-dropdown {
    @include media-breakpoint-up($nav-first-toggle-breakpoint) {
      max-width: none;
    }
  }
}

// "envelope" icon button in header
.navbar-messages {
  position: relative;

  .badge {
    position: absolute;
    top: px-to-rem(1);
    left: px-to-rem(23);
    right: auto;
    z-index: 10;

    @include media-breakpoint-up(md) {
      right: px-to-rem(15);
      left: auto;
    }
  }

  .dropdown-menu {
    max-width: 40em;

    // set dropdown-menu to open to the left
    left: auto;
    right: 0;

    a {
      white-space: normal;
    }

    @include media-breakpoint-up(sm) {
      // set dropdown-menu to open to the right
      left: 0;
      right: auto;
    }
  }

  @include media-breakpoint-up(sm) {
    .dropdown-menu {
      min-width: 29em;
    }
  }
}
