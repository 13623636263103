.tell-us-more-question {
  margin-bottom: 20px;
}

.highlight-question {
  background-color: $highlight;
}

.prev-answer {
  color: $nepal;

  strong {
    color: $st-topaz;
  }
}
