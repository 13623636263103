// Copyright 2015-2020 Scout Exchange, LLC. All Rights Reserved.
// Utilities // --------------------------------------------------

// We do a few special things in this scss file, so some specific lint rules are
// disabled

// classes for setting 10px padding
.p-l-sm {
  padding-left: 10px !important;
}

.p-r-sm {
  padding-right: 10px !important;
}

// classes for setting negative margins
.m-t-n-xs {
  margin-top: -5px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

// classes for setting 15px margin
.m-t-md {
  margin-top: 15px !important;
}

.m-r-md {
  margin-right: 15px !important;
}

// classes for setting 20px margin
.m-r-lg {
  margin-right: 20px !important;
}

.m-l-lg {
  margin-left: 20px !important;
}

// Layout
// utility classes to quickly help with layout

.bold {
  font-weight: 700;
}

.link-inactive {
  @extend .text-inactive;

  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.cursor-wait {
  cursor: wait;
}

%flex-column,
.flex-column {
  display: flex;
  flex-direction: column;
}

%align-items-center,
.align-items-center {
  align-items: center;
}

.underline,
%underline {
  text-decoration: underline !important;

  &:hover {
    text-decoration: none !important;
  }
}

