// coding:utf-8
// Copyright 2013-2025 Scout Exchange, LLC. All Rights
@use "sass:color";

// =========
// 1. variable overrides
// - - - - -
// override Bootstrap variables that we need in TPX
// --------

// Relative path to the Glyphicon font files in scout-ui
// Must come before scout-ui imports, as it overrides the 'icon-font-path'
// variable
$icon-font-path: "~scout-ui/assets/scout-bootstrap-sass/assets/fonts/bootstrap/";

// =========
// 2. scout-ui base styles - import the core Scout stylesheet from 'scout-ui',
// which includes Bootstrap
// --------
@import "~scout-ui/src/scss/core";

// =========
// 3. Connect variables, mixins, utilities
// --------
@import "scout-variables";
@import "scout-mixins";
@import "scout-utilities";

// =========
// 4. scout-ui components - import specific styles for scout-ui components
// --------
@import "~scout-ui/src/scss/general";
@import "~scout-ui/src/components/layout/content/content";
@import "~scout-ui/src/components/typography/typography";
@import "~scout-ui/src/components/layout/footer/footer";
@import "~scout-ui/src/components/layout/modal/modal";
@import "~scout-ui/src/components/layout/pagination/pagination";
@import "~scout-ui/src/components/input/typeahead";
@import "~scout-ui/src/components/button/button";
@import "~scout-ui/src/components/panel/alert";
@import "~scout-ui/src/components/loader/loader";

// 'dropdown' must be imported after 'button'
@import "~scout-ui/src/components/input/dropdown";

// Load the 'scout-ui' styles for the MultiLocation component
@import "~scout-ui/src/components/job/MultiLocation/multi-location";
@import "~scout-ui/src/components/layout/stack/control-row";
@import "~scout-ui/src/components/input/range";
@import "~scout-ui/src/components/input/input";
@import "~scout-ui/src/components/tabs/tabs";
@import "~scout-ui/src/components/input/radio";
@import "~scout-ui/src/components/util/popover";
@import "~scout-ui/src/components/input/datepicker";
@import "~scout-ui/src/components/layout/navigation/navigation";
@import "~scout-ui/src/components/util/icon";

// =========
// 5. Connect components
// --------

// Individual component styles
@import "components/icons";
@import "components/buttons";
@import "components/alerts";
@import "components/charts-and-graphs";
@import "components/candidate";
@import "components/back_link";
@import "components/button-row";
@import "components/header";
@import "components/message-thread";
@import "components/page_title";
@import "components/tabs";
@import "components/tooltip";
@import "components/radio";
@import "components/dropdown";
@import "components/announcement";
@import "components/tell_us_more_questions";
@import "components/text-block";
@import "components/payments";
@import "components/submissions-table";
@import "components/rating_option";
@import "components/executive-search";

// ============================================================ //
// global Bootstrap overrides //
// ============================================================ //

.btn {
  &:active,
  &.active {
    // remove box-shadow style from button "active" state
    box-shadow: none;
  }

  .material-icon-add {
    font-size: 21px;
    margin-right: -2px;
  }
}

.small {
  font-size: $font-size-sm;
}

.modal-footer {
  // align text left so that buttons align left as well (BS default is
  // 'text-right')
  text-align: left;
}

.form-group {
  // increase bottom margin (BS default is '15px')
  margin-bottom: 25px;
}

.has-error {
  .help-block {
    // shrink default text size for error messaging text
    font-size: 12px;
  }

  .input-group-addon {
    // keep addon text visible on error, default is $brand-danger on
    // $brand-danger
    color: $white;
  }
}

textarea.form-control {
  // set height (BS default is 'auto')
  height: 75px;
  resize: vertical;
}

.form-control {
  color: $scout-primary;

  @include media-breakpoint-down(xs) {
    font-size: $font-size-mobile-forms;
    height: auto;
  }
}

// ============================================================ //
// Base styles //
// ============================================================ //

img {
  max-width: 100%;
}

ul,
ol {
  padding-left: 25px;
}

// Layout styles // --------------------------------------------------

video {
  max-width: 100%;
}

.intro-box {
  margin-bottom: 20px;
}

.ellipsis-block {
  @include ellipsis;

  display: block;
}

.header-description {
  color: $gray;
  margin-bottom: 20px;
}

.notes-block {
  label {
    font-weight: $font-weight-normal;
  }
}

// allows icons to be displayed next to each other inline
.icons-inline {
  li {
    display: inline-block;
  }
}

.link-underlined {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    color: $primary-variant-dark;
    cursor: not-allowed;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

// App styles // --------------------------------------------------

// dropdown --------------------------
.dropdown {
  position: relative;
}

.dropdown-text {
  @include ellipsis;
}

.dropdown-header {
  color: $scout-primary;
  font-weight: $font-weight-bold;
}

// end dropdown --------------------------

.errorpage-sub-text {
  color: $primary-variant-dark;
}

.errorpage-alt-actions {
  margin: 50px 0 30px;
}

.list-spaced > li {
  margin-bottom: 10px;
}

.field-section {
  &.with-border {
    border: 1px solid $primary-variant-light;
    padding: 1em;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 15px;
  }
}

// layout tweaks for multi-field Search feature --------------------------
.multi-field-row {
  display: flex;

  label {
    font-weight: $font-weight-normal;
    font-size: 11px;
    margin-bottom: 2px;

    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }

  .form-group {
    margin-bottom: 0;
    width: 50%;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  // override scout-ui typeahead (i.e. react-select) style
  // stylelint-disable-next-line selector-class-pattern
  .Select-control {
    border-color: $input-border-color;
  }

  .form-control,
  .btn-primary {
    // adjust height to match react-select control
    height: 36px;
  }

  + .multi-field-row {
    margin-left: 15px;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

// end layout tweaks for multi-field Search feature --------------------------

.inline-field {
  align-items: center;
  display: flex;
  font-size: $font-size-sm;

  .dropdown,
  .form-control {
    margin-left: 7px;
  }

  .checkbox {
    margin: 0;

    label {
      margin-bottom: 0;
    }

    input {
      top: 1px;
    }
  }

  &:not(:last-child) {
    margin-right: 1.5em;
  }
}

// search --------------------------
.search-wrap {
  .input-group {
    margin-top: 3px;
  }
}

.filter-wrap,
.search-wrap {
  margin-top: 1em;
}

.search-filter-result-summary {
  font-size: $font-size-sm;
  font-style: italic;
  margin: 0 0 20px;
}

.inline-search-field {
  display: flex;

  .btn {
    margin-left: -1px;
  }

  @include media-breakpoint-down(xs) {
    .btn {
      font-size: $font-size-mobile-forms;
    }
  }
}

.no-results {
  font-size: 18px;
  text-align: center;
  padding: 20px 0;
  color: $primary-variant-darkest;
  line-height: 1.6;
}

.filter-search-row {
  @include media-breakpoint-up(md) {
    align-items: flex-end;
    display: flex;
    width: 100%;
  }
}

// sorting --------------------------
.sort-dropdown {
  font-size: 12px;

  a {
    cursor: pointer;
  }
}

@include media-breakpoint-up(sm) {
  // add margin-top to buttons when form is inline
  // margin value is equal to height of labels for the text fields
  // added within media query so that margin isn't added in small views
  .inline-form-btn-margin-top {
    margin-top: 24px;
  }
}

// fake checkbox --------------------------
// creates a square to mimic a 'checkbox'-type icon
// for when we're not actually using a checkbox

.hired-candidate-toggle {
  color: $primary-variant-darkest;

  a {
    color: $primary-variant-darkest;
  }
}

// ============================================================ //
// Layout //
// ============================================================ //

// positions alert to top of window --------------------------
.fixed-alert {
  display: none;
  position: fixed;
  width: 100%;

  // high value in order to override pendo icons, which have z-index of 19000
  z-index: 20000;
}

// vertically center text.
.flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// ============================================================ //
// Modules //
// ============================================================ //

// styles for custom file upload UI --------------------------
.upload-input {
  font-size: 12px;
  padding: 5px 0;
}

.uploaded-file-name {
  @extend .small;
  @extend .m-l-sm;

  color: $primary-variant-darkest;
}

.scrollable {
  height: 450px;
  overflow-y: scroll;
  border-top: 1px solid $primary-variant-dark;
  border-bottom: 1px solid $primary-variant-dark;
}

// to scroll Terms of Use (Terms of Service)
.scrollable-terms {
  // TODO this needs to be done with a outer container or something...
  @extend .scrollable;

  padding: 10px;
  margin-bottom: 20px;
}

// show validation icon immediately next to element --------------------------
// initially created for file upload errors
.validation-icon-right {
  position: relative;
  display: inline-block;
  min-height: 34px;

  .has-error & {
    margin-right: 34px;

    .material-icon-report {
      width: 0.5px;
      color: #910048;
      margin-right: -19px;
      margin-top: -4px;
    }
  }
}

.has-feedback label ~ .form-control-feedback {
  height: 5px;
  display: flex;
  align-items: center;
}

// stack-item styles --------------------------
// generic grouping style

.stack-item {
  padding: px-to-rem(12) px-to-rem(16);
  position: relative;
}

.stack-item-border-bottom {
  border-bottom: 1px solid $primary-variant-light;

  .scrollable &:last-child {
    border-bottom: 0;
  }
}

.stack-item-border {
  border: 1px solid $primary-variant-light;
}

.stack-callout {
  border: solid $gray-base 1px;
  border-color: $scout-primary;
  font-size: 0.8em;
  padding: 0.5em;
  display: inline-block;
  margin-bottom: 0.75em;
}

.stack-headline {
  @extend .m-b-xs;

  display: inline-block;
  font-weight: 600;
}

.stack-detail {
  + .stack-detail {
    margin-top: 5px;
    margin-bottom: 0;
  }
}

.stack-item-tight {
  .stack-detail {
    margin-top: 7px;
    line-height: 15px;
  }
}

.stack-info-primary {
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

.stack-inline {
  display: flex;
  align-items: center;
}

.stack-control-row {
  margin-right: 1em;
  margin-left: 1em;

  // stylelint-disable no-descending-specificity
  // adjust "control-row-side" padding to top align tabs and
  // dropdowns on the Marketplace page
  .control-row-side-right {
    margin-bottom: 5px;
  }

  .material-icon-add {
    margin-top: 0;
  }
}

.stack-abbreviated {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.5em 1em;

  .disabled-text {
    color: $disabled-text;
  }
}

.material-icon-person {
  font-size: 20px;
  margin-top: 0;
}

.stats-wrap {
  padding-left: 10px;
  border-left: 1px solid $typical-gray;
  margin-left: 20px;
  position: relative;

  .material-icon-person {
    position: absolute;
    left: -23px;
  }
}

.inline-text-and-icon {
  display: flex;
}

// filter icon display --------------------------
// display various states of filter icons on the right side of a job summary row
.icon-wrap {
  text-align: center;
  height: 15px;
  width: 15px;
  background-color: $primary-variant-lighter;
  border-radius: 35px;

  + .icon-wrap {
    @extend .m-l-sm;
  }

  &.active {
    color: $scout-primary;
    background-color: transparent;
  }
}

.item-highlight,
.item-de-emphasize {
  background: $primary-variant-lighter;

  .list-unstyled {
    margin-bottom: 0;
  }

  .icon-wrap {
    background-color: $primary-variant-light;
  }
}

// when the viewport is so wide that the candidate stats are on the top line...
@include media-breakpoint-up(md) {
  // ...make sure the bar graphs don't bump into the "Save Job" button
  .stats-in-summary .horiz-bar {
    padding-right: 80px;
  }
}

.stats-in-detail {
  width: 240px;
}

// This is for the "Submitted" flag or "Save Job" button in a job summary.
// With absolute positioning we can (a) keep this element in the upper
// right corner of the summary regardless of the viewport width, and
// (b) allow the statistics bars and the "Last update"
// line to extend below these graphics.
.job-submit-save {
  @extend .text-right;

  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}

.job-summary-badges {
  display: flex;
  gap: 0.25em;
}

// end stack-item styles

// styles for responsiveness rating UI --------------------------
// includes response-rating-list and scouticon- works
.response-rating-list {
  white-space: nowrap;
  display: inline-block;
  padding-left: 7px;
  position: relative;
  bottom: -2px;

  .page-header & {
    top: -5px;
    padding-left: 15px;
  }
}

// used for 'star' rating
.rating-inline {
  color: $star-default;
  display: inline-block;
  position: relative;
  line-height: 1;
  white-space: nowrap;

  &.clickable {
    color: $scout-action;
  }

  &.hiring {
    color: $star-hiring;
  }

  &.staffing {
    color: $star-staffing;
  }

  // adjust positioning of stars when inline
  // when listed within a "stack-detail"
  .stack-detail & {
    top: 3px;
  }
}

.stack-detail .rating-inline-text {
  color: $scout-action;
}

.scouticon-text {
  position: absolute;
  color: $white;
  font-size: 7px;
  font-weight: $font-weight-normal;
  text-transform: uppercase;
  top: 3px;
  width: 27px;
  text-align: center;
}

// ============================================================ //
// States //
// statuses, ui states
// ============================================================ //

// styling for job/candidate states --------------------------
.job-status {
  color: $scout-status;
  display: flex;
  align-items: center;
}

.status-inactive {
  color: $primary-variant-darker;
}

.status-unknown,
.status-pending,
.status-viewed,
.status-shared,
.status-interested,
.status-imported,
.status-notinterested,
.status-screening,
.status-hiringmanagerreview,
.status-interview,
.status-finalist,
.status-offer,
.status-withdrawn,
.status-onhold,
.sm-disabled-button-explanation, {
  color: $scout-status;
}

.status-hired,
.status-employerhired {
  color: $brand-success;
}

.status-rejected {
  color: $brand-danger;
}

.job-posted-text,
.job-exclusivity-text {
  color: $scout-status;
}

.suggested-by {
  color: $crusta;
}

.card-executive-search {
  background-color: color.change($brand-special, $alpha: 0.04);
  border: 1px solid $brand-special;
  gap: 8px;

  .svg-executive-search {
    width: 16px;
    height: 15px;
  }

  .svg-executive-search-outside-path {
    fill: $brand-special;
  }

  .svg-executive-search-inside-path {
    stroke: $brand-special;
  }
}

.onboard-next-steps .lead {
  font-size: 18px;
}

.tips-list {
  padding: 0 15px;

  li {
    margin-bottom: 5px;
  }

  h5 {
    border-bottom: 1px solid $scout-primary;
    font-weight: 700;
    line-height: inherit;
    padding-bottom: 10px;
  }
}

// For reference
// @media (min-width: $screen-sm-min) {}
// @media (min-width: $screen-md-min) {}
// @media (min-width: $screen-lg-min) {}
// @media (max-width: $screen-xs-max) {}

.inline-fields {
  align-items: baseline;
  display: flex;
  gap: 10px;

  &.with-wrap {
    flex-wrap: wrap;
  }

  // used for company_business_certifications
  .flex2 {
    flex: 2 0 150px;
  }

  .flex1 {
    flex: 1 0 75px;
  }

  .flex0 {
    flex: 0 0 25px;
  }

  // used for percent based layouts
  // 'with-2' = element's width is a little less than 50%
  .with-2-cols {
    width: calc(50% - 5px);
  }
}

.btn-cell {
  align-self: flex-start;

  .svg-btn {
    display: block;
    margin-top: px-to-rem(33);
  }
}

.pause-popover-details {
  font-style: normal;
  margin-top: 10px;
}

.default-info {
  padding-left: 15px;
}

.message-box-warning {
  border: 1px solid $scout-status;
  color: $scout-status;
  font-size: $font-size-sm;
  margin: 10px 0 15px;
  padding: 15px;
}

.message-box-default {
  border: 1px solid $scout-primary;
  margin: 10px 0 15px;
  padding: 15px;
}

.improve-rating-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .rating-inline {
    background-color: $white;
    margin-top: -21px;
    padding: 0 10px;
    position: absolute;
  }
}

.settings-page-wrap {
  .h3 {
    border-bottom: 1px solid $primary-variant-dark;
    padding-bottom: 10px;
  }
}

.l-flex {
  .container {
    max-width: 100%;
  }

  .settings-page-wrap {
    .svg-thumbs-up {
      margin-right: 0;
    }
  }
}

.l-side {
  .job-submit-save {
    position: static;
  }

  .sidebar-head {
    &:first-child {
      margin-top: 0;
    }
  }

  .checkbox {
    // since text in sidebar is smaller (12px) than default page size, we need
    // to, ...increase line-height so the text center aligns with each checkbox
    line-height: 1.8;

    // ...reduce margin-top value even more (from -5) to bring the
    // checkboxes in the sidebar closer together
    + .checkbox {
      margin-top: -8px;
    }
  }

  .form-group {
    margin-bottom: px-to-rem(20);
  }
}

// stylelint-enable no-descending-specificity

.l-side-left {
  + .l-main {
    padding: 0 1em;
  }
}

.l-flex-column {
  @extend %flex-column;

  height: 100%;
}

.keyword-form-group {
  .label-wrap {
    display: flex;
  }

  .control-label {
    margin-right: 0;
  }

  .field-description {
    padding-right: 40px;
  }

  .textarea-wrap {
    width: 100%;
    position: relative;

    textarea {
      min-height: 56px;
    }
  }
}

.req-type-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px 0;

  > div {
    border: 1px solid $primary-variant-light;
    border-radius: 1rem;
    padding: 1% 2%;
    width: 32%;
  }

  h4 {
    font-weight: $font-weight-bold;
  }

  hr {
    border-color: $scout-status;
    border-width: 2px;
    margin: 15px 0;
    width: 40px;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.submission-flex {
  display: flex;
  font-size: $font-size-sm;

  .flex-col {
    flex: 1;
    padding: 1em;

    > div + div {
      margin-top: 3px;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-top: 3px;
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }
}

.radio-group {
  margin-bottom: 25px;

  .control-label {
    margin-bottom: 0;
  }

  .help-block {
    margin-top: 0;
  }
}

.temp-pricing-msg {
  margin-bottom: px-to-rem(36);

  @include media-breakpoint-up(lg) {
    margin-top: px-to-rem(8);

    .form-group {
      margin-bottom: 0;
    }
  }

  .help-block {
    margin-bottom: 0;
  }
}

.temp-markup-display {
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    margin-bottom: 5px;
  }
}

.candidate-activity-filter {
  display: flex;

  .candidate-count-filter {
    margin-right: 10px;
    width: 7rem;
  }

  .dropdown-menu {
    // using min-width to override component level styles
    min-width: 5rem;
  }
}

.onboarding-requirement {
  overflow-wrap: anywhere;
}

// styles for "Only in Scout" notification banner
.only-in-scout {
  margin-bottom: spacer(1.5);

  strong {
    color: $only-in-scout-text;
    font-weight: $font-weight-bold;
  }

  .only-in-scout-inner {
    background-color: $white;
    border: 2px solid $only-in-scout-text;
    display: inline-block;
    padding: spacer(0.5) spacer(1);
  }

  .only-in-scout-icon {
    display: inline-block;
    margin: 0 spacer(2) 0 spacer(1.5);
    position: relative;
    width: spacer(5);

    .svg-scout-dog {
      bottom: -5px;
      position: absolute;
    }
  }

  .popover-trigger {
    font-size: $font-size-sm;
    padding-left: 0;
  }
}

.roi-rating-popover {
  max-width: 360px;
}

.candidate-requirements-icon {
  position: relative;
  top: 3px;
}

.fee-split {
  color: $fee-split;
}

// admin page placement views dropdown
.placements-view {
  font-size: 12px;
  font-weight: $font-weight-normal;
  text-align: left;
  margin-bottom: 0;
  margin-right: 32px;
}

.placement-rights-form-group {
  // 'inline-block' shrinks this wrapper to the input width
  // and helps with positioning the error icon
  display: inline-block;
  font-size: $font-size-sm;
  margin-bottom: 5px;

  .help-block {
    margin-bottom: 0;
  }

  .control-label {
    margin-bottom: 0;
  }

  .form-control {
    width: 135px;
    height: auto;
    font-size: $font-size-sm;
    display: inline-block;
    padding-right: 30px;

    &:disabled {
      color: $btn-dropdown-disabled-color;
    }
  }

  // need full selector to override Bootstrap
  &.has-feedback label ~ .form-control-feedback {
    top: 23px;
    right: 5px;
  }
}

.admin-actions {
  display: flex;
  flex-direction: column;
  text-align: right;

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .admin-action-impersonate {
      margin-right: auto;
    }
  }
}

.admin-user-row {
  td {
    vertical-align: middle !important;
  }
}

// styles for new Placements List page --------------------------

.placement-page-list {
  .stack-item {
    margin-bottom: 10px;
  }

  .stack-headline {
    font-size: $font-size-base;
    margin-top: 0;
  }

  .stack-detail {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.sliver-medalist-box {
  @extend %align-items-center;

  border: 2px solid $data-viz-6;
  display: flex;
  padding: spacer(2.5) spacer(3);
  grid-gap: spacer(3);
}

.silver-medalist-description {
  margin-bottom: spacer(0.5);

  .popover-trigger {
    @extend %underline;

    padding-left: 0;
    text-decoration: underline;
  }
}

// styles for limitSubmissions submit button variants ---

.limit-submissions-box {
  border: 1px solid $gray-base;
  display: flex;
  padding: spacer(2.5);
}

.limit-submission-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid $gray-base;
  padding: 0;
  align-self: center;
}

.limit-submission-no-limit-border {
  border-color: $brand-success;
}

.limit-submission-warning-border {
  border-color: $brand-warning;
}

.limit-submission-submit {
  align-self: center;
}

.limit-submission-description-inner {
  display: flex;
  grid-gap: spacer(2.5);
}

.limit-submission-link {
  white-space: nowrap;
  align-self: flex-end;
}

.limit-submission-warning-text {
  color: $brand-warning;
}


// styles for Diversity Info ---

.diversity-info-img {
  vertical-align: top;
  margin-top: 2.5px;
}

.svg-thumbs-down {
  height: 15px;
  width: 15px;
  margin-right: 5px;

  .thumbs-down-stroke {
    stroke-width: 9;
  }
}

.star-category {
  align-items: end;
  display: flex;

  .rating-inline {
    margin-right: 2px;
  }
}

.profile-image {
  min-width: 100%;
}

.table-no-top-border th {
  border-top: 0;
}

// styles for delaying element from rendering

.delay {
  opacity: 0;
  animation: show-element 2s forwards;
}

@keyframes show-element {
  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
