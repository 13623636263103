// Copyright 2024 Scout Exchange, LLC. All Rights Reserved.

// Buttons
// --------------------------------------------------

.btn-envelope {
  background: transparent;
  line-height: 1;
  padding: 0;
  width: auto;

  .caret {
    display: none;
  }

  .svg-envelope {
    height: px-to-rem(17);
    width: px-to-rem(27);

    path {
      stroke: $white;
    }

    &-body,
    &-top {
      fill: $scout-primary;
    }
  }

  &.has-messages {
    .svg-envelope {
      width: px-to-rem(27);

      path {
        stroke: $scout-action;
      }
    }
  }

  &:hover {
    .svg-envelope {
      path {
        stroke: $scout-action;
      }

      &-body,
      &-top {
        fill: $foam;
      }
    }
  }
}
