// Copyright 2013-2020 Scout Exchange, LLC. All Rights Reserved.

@mixin grow-up-border(
  $startBordColor: #ccc,
  $startHeight: 2px,
  $bordWidth: 1px,
  $endBordColor: #ccc
) {
  @include growUp;

  &::after {
    height: $startHeight;
    border: $bordWidth solid $startBordColor;
  }

  &:hover {
    &::after {
      border-color: $endBordColor;
    }
  }

  &.active {
    &::after {
      height: 100%;
      border-color: $endBordColor;
    }
  }
}

@mixin circle-border(
  $radius: 50%,
  $style: solid,
  $width: 1px
) {
  border-radius: $radius;
  border-style: $style;
  border-width: $width;
}

@mixin tblock-hover-dark {
  border-radius: $tblock-radius;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $tblock-dark-hover-bg;
  }
}

@mixin tblock-hover-light {
  border-radius: $tblock-radius;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $tblock-light-hover-bg;
  }
}
