// Copyright 2020-2025 Scout Exchange, LLC. All Rights

.dropdown-menu {
  width: auto;
  min-width: 70%;

  .btn-link {
    width: 100%;
    text-align: left;
    display: block;
    text-decoration: none;
    color: $body-color;
    padding: 3px 20px;

    &:hover {
      background-color: $primary-variant-lighter;
    }
  }
}

.multi-dropdown {
  .dropdown-menu {
    .btn-link {
      // styles to fix "clear all" button
      // and override what's coming
      // from .dropdown-menu .btn-link
      color: $scout-action;
      padding: 0;
      width: auto;
    }
  }
}

.navbar-userinfo-dropdown {
  .dropdown-menu {
    li {
      padding: 3px 17px;
    }

    li.rating {
      padding: unset;
    }

    .divider {
      margin: 9px 24px;
      background-color: $warm-neutral-2;
    }
  }
}

.navbar .dropdown-menu .btn-link {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: $nav-action-border-radius;
  color: $white;
  cursor: pointer;
  padding: 12px;
  display: block;

  &:hover {
    border-color: $white;
  }

  &:active {
    border-color: $scout-accent;
  }

  &:disabled,
  &.disabled {
    color: $primary-variant-light;

    &:hover {
      border-color: transparent;
      cursor: not-allowed;
    }
  }

  @media (min-width: 768px) {
    padding: 7px;
    outline: 0;
  }
}
