// coding:utf-8
// Copyright 2024 Scout Exchange, LLC. All Rights

// Styles for the Executive Search feature

.item-executive-search {
  background-color: color.change($brand-special, $alpha: 0.04);
  border-bottom-color: $brand-special;
}

.badge-executive-search {
  background-color: $brand-special;
}
