// Tab styles

// Handles positioning of badge within a tab since Scout UI doesn't have this
// feature yet
.nav-tabs {
  .badge {
    margin-top: -3px;
    margin-left: 7px;
  }

  .material-icon-add {
    font-size: 21px;
    margin-top: -15px;
  }

  .svg-thumbs-up {
    margin-right: 3px;
  }
}

// Override Scout UI's "tab-content" padding to be consistent with Connect's
// layout
.tabs-with-border {
  .tab-content {
    padding: spacer(4);
  }
}

// "just tabs" is used to style a group of clickable things to look like tabs
// without changing the code to actually use the Tabs component
// example: the All/Recommended/Exclusive Jobs "tabs" on Marketplace
// These styles rely on having the Tabs classes of "nav nav-tabs" as parents.
.just-tabs {
  // need this specificity to override Bootstrap
  .nav-link {
    padding: 0;
    margin-top: px-to-rem(-1);
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: $font-weight-semibold;
    margin-bottom: 0;
    padding: spacer(2) spacer(3);
  }
}

.stack-item-border-bottom {
  &.has-filters {
    .nav-tabs {
      border-bottom: 0;
    }
  }
}

// === adjustments for the layout of items within the ControlRow components

// Had to define "min-width: 0" to apply "display: flex" on ".control-row"
// immediately. The component styles don't kick in until about 420px since
// that's what the "xs" breakpoint is.
@media (min-width: 0) {
  .has-filters {
    .control-row {
      display: flex;
      align-items: flex-end;

      // control-row styles don't include this
      flex-wrap: wrap;
      font-size: $font-size-base;
    }

    .control-row-side-left {
      // remove margin underneath the tabs so that they look "connected" to
      // border that runs underneath them
      margin-bottom: 0;

      // "min-width: 0" is fix for a flex child element. This will allow the
      // tabs to scroll horizontally if the width is too narrow.
      min-width: 0;
    }
  }
}

@media (max-width: 1040px) and (min-width: 0) {
  .has-filters {
    .control-row {
      // The Tabs and the "Sort" dropdown are stacked vertically to start until
      // the "max" breakpoint is reached. This was done to avoid having to code
      // for multiple ranges of breakpoints for every instance where the Tabs
      // and dropdown can line up horizontally.
      flex-direction: column;

      .inline-fields {
        margin-bottom: 3px;
      }
    }

    .control-row-side-left {
      // In a flex layout, "margin-right: auto" fills the space between the item
      // and it's sibling with margin and pushes that sibling all the way
      // against the edge of the flexbox parent
      margin-right: auto;

      // Set the order as "1" so that the this element appears second after its
      // sibling in the visual flow.
      order: 1;
    }
  }
}
