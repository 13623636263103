.radio-vertical {
  display: block;

  .radio {
    margin: 5px 20px 5px 0;

    label {
      min-height: auto;
    }

    input {
      margin-top: 3px;
    }
  }
}
