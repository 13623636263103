// coding:utf-8
// Copyright 2013-2024 Scout Exchange, LLC. All Rights

// mixin defaults = scoutIconBase(
//   $float: left, $margin: 0, $padding: 0,
//   $height: 15px, $width: 15px,
//   $backPosition: 0 0, $backRepeat: no-repeat
// )

@use "sass:math";

.scouticon {
  background-image: url(icons/sprite.png);
}

.scouticon-bone-tilt {
  @include scoutIconBase($backPosition: 0 -16px);
}

.scouticon-bone-flat {
  @include scoutIconBase($width: 27px);
}

.icon-inactive {
  opacity: 0.5;
}

// used to position icons inline with text, either before or after the text
.inline-icon-wrap {
  position: relative;

  .inline-icon {
    position: absolute;
    width: 23px;
    height: 23px;
    top: -1px;
  }

  &.icon-is-before {
    padding-right: 25px;

    .inline-icon {
      left: 0;
    }
  }

  &.icon-is-after {
    padding-left: 24px;

    .inline-icon {
      right: 0;
    }
  }

  .small & {
    .inline-icon {
      top: -2px;
    }
  }
}

.badgeicon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-image: url(icons/sprite.svg);
  background-size: 80px;
}

.badgebutton {
  &:hover,
  &:focus,
  &.focus {
    background-color: $primary-variant-lighter;
  }

  &:active,
  &.active {
    background-color: $scout-primary;
  }

  &.disabled,
  &[disabled] {
    background-color: $primary-variant-light;
    border-color: $primary-variant-light;
  }
}

.svg-edit-pencil {
  width: 16px;
  height: 16px;
}

.svg-executive-search {
  width: 12px;
  height: 11px;
  position: relative;
  top: -1px;
}

.svg-executive-search-outside-path {
  fill: $white;
  stroke: unset;
  stroke-width: unset;
}

.svg-executive-search-inside-path {
  fill: none;
  stroke: $white;
  stroke-width: 0.75px;
}

.svg-pencil-stroke {
  fill: transparent;
  stroke: $scout-action;
  stroke-width: 1px;
}

.svg-pencil-fill {
  fill: $scout-action;
  stroke-width: 0;
}

.svg-new-window {
  width: 16px;
  height: 16px;
}

.svg-new-window-stroke {
  fill: transparent;
  stroke: $scout-action;
  stroke-width: 1px;
}

// utility adjustments for badgeicons
.badgeborder {
  border: 1px solid $scout-primary;
}

.badgeround {
  border-radius: 50%;
}

.badgebackwhite {
  background-color: $white;
}

.badgeicon-audio {
  background-position: -9px -9px;
  background-size: 81px;
  width: 23px;
  height: 23px;
}

// ============================================================ //
// SVG
// ============================================================ //

// set SVG defaults // --------------------------------------------------

line,
path {
  fill: transparent;
  stroke: $scout-primary;
  stroke-width: 4;
}

// SVG icons // --------------------------------------------------

.arrow-stroke {
  stroke-width: 2;
}

.svg-envelope {
  path {
    stroke-width: 1px;
  }

  &-body,
  &-top {
    fill: transparent;
  }
}

.svg-info-circle {
  height: 16px;
  width: 16px;

  path {
    stroke-width: 0;
  }
}

.svg-info-circle-inner-circle {
  fill: $white;
}

.svg-info-circle-outer-circle,
.svg-info-circle-letter-i path {
  fill: $scout-action;
}

.svg-scout-dog {
  > path {
    fill: $limed-spruce;
    stroke-width: 0;
  }
}

.svg-info-icon {
  height: 1.6rem;
  width: 1.6rem;

  .svg-info-circle {
    fill: transparent;
    stroke: $scout-action;
    stroke-width: 1px;
  }

  .svg-info-i {
    fill: $scout-action;
    stroke: transparent;
  }
}

.svg-help-path {
  fill: $scout-action;
  stroke-width: 0;
}

@mixin active-styles {
  background-color: $svg-active-bg;
  border-color: $svg-active-border;

  line,
  path {
    fill: $svg-active-fill;
    stroke: $svg-active-stroke;
  }
}

@mixin disabled-styles {
  background-color: $svg-disabled-bg;
  border-color: $svg-disabled-border;
  color: $svg-disabled-color;
  cursor: auto;
  pointer-events: none;

  line,
  path {
    fill: $svg-disabled-fill;
    stroke: $svg-disabled-stroke;
  }
}

.svg-badge-default {
  background-color: $white;
  border-color: $scout-action;
  cursor: pointer;

  line,
  path {
    stroke: $scout-action;
  }

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    background-color: $svg-hover-bg;
    border-color: $svg-hover-border;

    // closed shapes get filled with white
    line,
    path {
      fill: $svg-hover-fill;
    }

    // line/path override - remove fill from arrow head
    .arrow-stroke {
      fill: transparent;
    }

    // line/path override - keep help path figure fill as scout-action
    .svg-help-path {
      fill: $scout-action;
    }
  }

  &:active,
  &.active {
    @include active-styles;

    // line/path override - remove fill from arrow head
    .arrow-stroke {
      fill: transparent;
    }

    // line/path override - keep help path figure fill as white
    .svg-help-path {
      fill: $white;
    }
  }

  &.disabled,
  &[disabled] {
    @include disabled-styles;

    // line/path override - remove fill from arrow head
    .arrow-stroke {
      fill: transparent;
    }

    // line/path override - keep help path figure fill as svg-disabled-stoke
    .svg-help-path {
      fill: $svg-disabled-stroke;
    }
  }
}

.svg-xs {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $svg-icon-xs-size;
  width: $svg-icon-xs-size;

  .svg {
    padding: $svg-icon-xs-padding;
    width: 100%;
    height: 100%;
  }
}

.svg-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $svg-icon-sm-size;
  width: $svg-icon-sm-size;

  .svg {
    padding: $svg-icon-sm-padding;
    width: 100%;
    height: 100%;
  }

  .svg-discussion {
    padding: 5px $svg-icon-sm-padding 3px;
  }
}

.svg-lg {
  display: inline-block;
  height: $svg-icon-lg-size;
  width: $svg-icon-lg-size;
  padding: math.floor(math.div($svg-icon-lg-size, 4));
}

.inline-svg {
  display: flex;

  &.with-text {
    .svg-wrap {
      height: 15px;
    }

    .svg {
      flex: 0;
      margin-left: 5px;
      position: relative;
      top: 1px;
      width: 13px;
    }

    path {
      stroke-width: 8;
    }
  }
}

.dot-icon {
  background-color: $bright-red;
  border-radius: 2rem;
  height: px-to-rem(12);
  width: px-to-rem(12);
}

.svg-btn {
  display: inline-block;
  position: relative;
  text-align: center;

  + .svg-btn {
    margin-left: 1em;
  }

  .badge-text {
    display: block;
    margin-top: 3px;
  }

  .badge {
    position: absolute;
    top: -3px;
    right: -7px;
    text-align: left;
    z-index: 10;
  }

  .dot-icon {
    position: absolute;
    top: -3px;
    right: -5px;
    text-align: left;
    z-index: 11;
  }
}

.icon-row {
  display: flex;

  .svg-btn {
    + .svg-btn {
      margin-left: 1rem;
    }
  }
}

.svg-btn-disabled {
  cursor: ne-resize;
  pointer-events: none;
}

.svg-badge {
  @include circle-border;
}

.svg-badge-notice {
  background-color: $white;
  border-color: $scout-status;
  cursor: pointer;

  &:hover,
  &.hover,
  &:focus,
  &.focus {
    background-color: $svg-hover-bg;
    border-color: $scout-status;

    // closed shapes get filled with white
    line,
    path {
      fill: $svg-hover-fill;
    }
  }

  &:active,
  &.active {
    @include active-styles;
  }

  &.disabled,
  &[disabled] {
    @include disabled-styles;
  }
}

// SVG icon-specific styles // ------------------------------------------------

// icon.jsx > CloseX
.close-x-stroke {
  stroke-width: 1;
}

// icon.jsx > Audio
.audio-stroke {
  stroke-width: 1;
}

// icon.jsx > Discussion
.discussion-path {
  stroke-width: 1;
}

// icon.jsx > Checklist
// Checklist viewBox is about 36px x 36px. Other icons are different.
// To account for this difference, stroke-width value is also different.
.checklist-stroke {
  stroke-width: 3;
}

.checklist-fill {
  fill: $white;
}

.svg-email-envelope {
  width: 40px;
  height: 32px;
}

.svg-email-envelope-path {
  stroke-width: 0;
  fill: $scout-primary;
}

.svg-announcement {
  height: 100%;
  padding: 2px 3px;
  width: 100%;
}

.svg-announcement-path {
  stroke: $scout-status;
  stroke-width: 1;
}

.svg-announcement-fill {
  fill: $white;
}

.svg-user {
  width: spacer(10);
  height: spacer(10);
}

.svg-user-body {
  fill: $white;
  stroke: transparent;
  stroke-width: 1;
}

.svg-user-outline {
  fill: transparent;
  stroke: $white;
  stroke-width: 1;
  height: spacer(9.5);
  width: spacer(9.5);
}

.svg-thumbs-up {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-top: -3px;
  stroke-width: 9;

  .thumbs-up-stroke {
    stroke-width: 9;
  }
}
