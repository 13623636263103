// Copyright 2024 Scout Exchange, LLC. All Rights Reserved.

// Back link styles

.back-link {
  position: absolute;
  top: px-to-rem(12);
  left: px-to-rem(-18);
  color: $scout-primary;
  cursor: pointer;
}

.back-link-wrap {
  z-index: 2;
}

@include media-breakpoint-down(xs) {
  .back-link-wrap + .page-title h1 {
    margin-left: px-to-rem(18);
  }

  .back-link {
    left: 0;
  }
}
