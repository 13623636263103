// Styles for the candidate cards

.candidate-card {
  border: 1px solid $primary-variant-light;
  margin-bottom: 1em;

  .main-content {
    flex: 1 0 0;
    min-width: 50%;
    padding: 0.75em 1em 0.35em;
  }

  .side-content {
    min-width: 50%;
    padding: 0.35em 1em 0.75em;
  }

  @include media-breakpoint-up(md) {
    .main-content,
    .side-content {
      padding: 0.75em 1em;
    }
  }

  &:last-child {
    margin: 0;
  }
}

.candidate-card-header,
.candidate-card-details {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.candidate-card-header {
  background-color: $primary-variant-lighter;
  border-bottom: 1px solid $primary-variant-light;

  h4 {
    display: inline-block;
    margin: 0;
  }

  .main-content,
  .side-content {
    display: flex;
    align-items: center;
  }

  .main-content {
    justify-content: flex-start;

    // override the default padding to reduce spacing on the left side
    .popover-trigger {
      padding-left: 0;
      margin-left: 7px;
    }

    .svg-btn {
      margin-left: 7px;
    }
  }

  .side-content {
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
  }
}

.candidate-card-details {
  @include media-breakpoint-up(md) {
    .side-content {
      border-left: 1px solid $primary-variant-light;
    }
  }

  .main-content,
  .side-content {
    > div {
      font-size: 12px;
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
