// coding:utf-8
// Copyright 2013-2020 Scout Exchange, LLC. All Rights

// Animation
// styles and controls for animation
// --------------------------------------------------

@keyframes grow-bar {
  from {
    width: 0%;
  }
}

.horiz-bar {
  display: flex;
  align-items: center;
}

.horiz-bar-segment {
  background-color: $scout-action;
  height: 10px;
  margin-right: 5px;
}

.first-stat-primary > div .horiz-bar-segment {
  background-color: $primary-variant-dark;
}

.first-stat-primary > div:first-child .horiz-bar-segment {
  background-color: $scout-action;
}

.horiz-bar-value {
  font-weight: $font-weight-bold;
  flex-basis: 23px;
}

.horiz-bar-animate {
  animation-name: grow-bar;
  animation-duration: 2s;
  animation-timing-function: $ease-type;
}

.roi-rating-bar {
  font-size: $font-size-lg;
  line-height: 1;

  &-1 {
    color: $roi-rating-poor;
  }

  &-2,
  &-3 {
    color: $roi-rating-medium;
  }

  &-4,
  &-5 {
    color: $roi-rating-good;
  }
}
