// Alerts
// --------------------------------------------------

.alert-info {
  .svg-thumbs-up {
    height: 24px;
    width: 24px;
    margin-right: 0;

    .thumbs-up-stroke {
      stroke: $brand-info;
      stroke-width: 8;
    }
  }

  .material-symbols-outlined {
    color: $brand-info;
  }
}

.alert-close {
  font-size: 2rem;
  line-height: 1;
  top: -0.2rem;
}

.alert-container {
  // adjust default bottom margin value on all ".alert" items
  position: relative;

  .alert {
    margin-bottom: 1px;

    // set bottom margin of last-child to the same value used by Bootstrap
    // this also applies when there's only one ".alert" child
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.l-side-left + .l-main {
  .alert-container {
    margin-left: -1em;
    margin-right: -1em;
  }
}
