//  page_title styles

.announcement-heading {
  color: $scout-status;
  font-size: 20px;
}

.announcement {
  font-size: 16px;
}

.announcement-divider {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid $primary-variant-light;
}

.announcement-divider-last {
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px dashed $scout-status;
}
